import React, { useEffect, useState } from 'react';
import { Grid, GridProps, styled, Typography } from '@mui/material';
import { motion, MotionProps } from 'framer-motion';
import { graphql, useStaticQuery } from 'gatsby';

const Container = styled(
  ({
    motionProps,
    ...props
  }: { motionProps: MotionProps } & Partial<GridProps>) => (
    <motion.div {...motionProps}>
      <Grid {...props} />
    </motion.div>
  ),
)`
  margin: 12px 0px;
  overflow: hidden;
`;

const CustomP = styled('div')`
  a {
    color: #45c7ff;
  }
`;

const CustomPp = styled('span')`
  font-size: 14px;
`;

const CustomT = styled('div')`
  color: #ffffff;
  flex: 1;
  justify-content: left;
  text-align: left;
  font-family: HCo Gotham SSm, Gotham, sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;

  .promo-title {
    font-family: Luckiest Guy;
    font-weight: 400;
    font-size: 32px;
    line-height: 32px;
  }

  @media screen and (max-width: 1286px) {
    font-weight: 500;
    font-size: 17px;
    line-height: 21px;

    .promo-title {
      font-family: Luckiest Guy;
      font-size: 25px;
      line-height: 25px;
    }
  }

  @media screen and (max-width: 1146px) {
    font-weight: 500;
    font-size: 14px;
    line-height: 14px;

    .promo-title {
      font-family: Luckiest Guy;
      font-size: 25px;
      line-height: 25px;
    }
  }

  @media screen and (max-width: 957px) {
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;

    .promo-title {
      font-family: Luckiest Guy;
      font-size: 20px;
      line-height: 20px;
    }
  }

  @media screen and (max-width: 700px) {
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;

    .promo-title {
      font-family: Luckiest Guy;
      font-size: 16px;
      line-height: 18px;
    }
  }
`;

const PromoInformation: React.FC<{ visible: boolean }> = ({ visible }) => {
  const [mounted, setMounted] = useState(visible);
  const [popUp, setPopUp] = useState(false);
  let bannerContent = '';
  let bannerTermsAndCondition = '';
  const useFirstBanner = false;
  let bannerBgImage = '';
  let bannerBgImageTwo = '';

  const { banner } = useStaticQuery(graphql`
    query MyQuery {
      banner: allGraphCmsBanner {
        edges {
          node {
            bannerContent {
              html
            }
            bannerContentTwo {
              html
            }
            bannerTermsAndCondition {
              html
            }
            bannerTermsAndConditionTwo {
              html
            }
            termsAndConditionLink
            termsAndConditionLinkTwo
            isVisible
            bannerBgImage {
              gatsbyImageData
            }
            bannerBgImageTwo {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);
  try { 
    bannerContent = banner.edges[0]?.node?.bannerContent?.html;
    bannerTermsAndCondition =
      banner.edges[0]?.node?.bannerTermsAndCondition?.html;
    bannerBgImage =
      banner.edges[0]?.node?.bannerBgImage?.gatsbyImageData?.images?.fallback
        ?.src;
    bannerBgImageTwo =
      banner.edges[0]?.node?.bannerBgImageTwo?.gatsbyImageData?.images?.fallback
        ?.src;
  } catch (error) {}

  useEffect(() => {
    if (visible) {
      setMounted(true);
    }
  }, [visible]);

  if (!mounted) {
    return null;
  }
  return (
    <Container
      id="banner-container"
      style={{
        width: '100vw',
        height: 'fit-content',
        background: useFirstBanner
          ? `url(${bannerBgImage})`
          : `url(${bannerBgImageTwo})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundColor: '#3AB4EB',
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        flexDirection: 'column',
      }}
      motionProps={{
        animate: visible ? 'visible' : 'visible',
        transition: {
          type: 'linear',
          duration: 0.2,
        },
        variants: {
          visible: {
            y: 0,
            opacity: 1,
          },
          hidden: {
            y: 100,
            opacity: 0,
          },
        },
        onAnimationComplete: (definition) => {
          if (definition === 'hidden') {
            setMounted(false);
          }
        },
      }}
    >
      <Grid
        item
        md={12}
        sx={{
          display: {
            xs: 'none',
            sm: 'none',
            md: 'block',
            lg: 'block',
            xl: 'block',
          },
          mt: 0,
          pt: 0,
          pb: 0,
        }}
      >
        <CustomT>
          <div
            id="banner-content"
            style={{
              textAlign: 'center',
              justifyContent: 'center',
            }}
          >
            <div>
              <CustomPp
                style={{ fontSize: '16px' }}
                dangerouslySetInnerHTML={{
                  __html: bannerContent,
                }}
              />
            </div>
          </div>
        </CustomT>
        {/* start Popup */}
        <div
          style={{
            display: popUp ? 'flex' : 'none',
            alignItems: 'center',
            justifyContent: 'center',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: '999',
            backgroundColor: 'rgba(0,0,0,0.3)',
          }}
        >
          <div id="banner-popup" className="popUp">
            <button
              type="button"
              style={{
                position: 'absolute',
                border: '2px solid black',
                right: '15px',
                borderRadius: '50%',
                fontSize: '18px8',
              }}
              onClick={() => setPopUp(false)}
            >
              &times;
            </button>
            <div>
              <h2
                style={{
                  margin: '8px 0px',
                  textAlign: 'justify',
                  fontSize: '18px',
                }}
              >
                Terms and Conditions
              </h2>
              <CustomP
                dangerouslySetInnerHTML={{
                  __html: bannerTermsAndCondition,
                }}
              />
            </div>
          </div>
          <div id="banner-popup-two" className="popUp">
            <button
              type="button"
              style={{
                position: 'absolute',
                border: '2px solid black',
                right: '15px',
                borderRadius: '50%',
                fontSize: '18px8',
              }}
              onClick={() => setPopUp(false)}
            >
              &times;
            </button>
            <div>
              <h2
                style={{
                  margin: '8px 0px',
                  textAlign: 'justify',
                  fontSize: '18px',
                }}
              >
                Terms and Conditions
              </h2>
              <CustomP
                dangerouslySetInnerHTML={{
                  __html: bannerTermsAndCondition,
                }}
              />
            </div>
          </div>
        </div>
        {/* End of PopUp */}
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        sx={{
          display: {
            xs: 'block',
            sm: 'block',
            md: 'none',
            lg: 'none',
            xl: 'none',
          },
          mt: 0,
        }}
      >
        <CustomT>
          <div
            id="banner-content-mobile"
            className=""
            style={{
              textAlign: 'center',
              // fontFamily: 'Luckiest Guy',
            }}
          >
            <div>
              <CustomPp
                style={{ fontSize: '12px' }}
                dangerouslySetInnerHTML={{
                  __html: bannerContent,
                }}
              />
            </div>
          </div>
        </CustomT>
        {/* start Popup */}
        <div
          style={{
            display: popUp ? 'flex' : 'none',
            alignItems: 'center',
            justifyContent: 'center',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: '999',
            backgroundColor: 'rgba(0,0,0,0.3)',
          }}
        >
          <div id="banner-popup-mobile" className="popUp">
            <button
              type="button"
              style={{
                position: 'absolute',
                border: '2px solid black',
                right: '15px',
                borderRadius: '50%',
                fontSize: '18px8',
              }}
              onClick={() => setPopUp(false)}
            >
              &times;
            </button>
            <div>
              <h2
                style={{
                  margin: '8px 0px',
                  textAlign: 'justify',
                  fontSize: '18px',
                }}
              >
                Terms and Conditions
              </h2>
              <CustomP
                dangerouslySetInnerHTML={{
                  __html: bannerTermsAndCondition,
                }}
              />
            </div>
          </div>
          <div id="banner-popup-mobile-two" className="popUp">
            <button
              type="button"
              style={{
                position: 'absolute',
                border: '2px solid black',
                right: '15px',
                borderRadius: '50%',
                fontSize: '18px8',
              }}
              onClick={() => setPopUp(false)}
            >
              &times;
            </button>
            <div>
              <h2
                style={{
                  margin: '8px 0px',
                  textAlign: 'justify',
                  fontSize: '18px',
                }}
              >
                Terms and Conditions
              </h2>
              <CustomPp
                dangerouslySetInnerHTML={{
                  __html: bannerTermsAndCondition,
                }}
              />
            </div>
          </div>
        </div>
        {/* End of PopUp */}
      </Grid>
    </Container>
  );
};

export default PromoInformation;
