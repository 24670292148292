/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext, useState } from 'react';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import { RichTextContent } from '@graphcms/rich-text-types';
import { graphql, useStaticQuery } from 'gatsby';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { motion } from 'framer-motion';
import GlobalGrid from '../GlobalGrid';
import { ISpec, useGlobalState } from '../GlobalState';
import Button from '../button';
import HeroAnimation from './HeroAnimation';
import { SectionTypes } from '../../templates/page';
import Path from '../../assets/images/path.svg';
import { SupportContext } from '../../context/SupportContext';

const Container = styled(GlobalGrid)`
  flex-direction: column;
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 3rem;
  text-align: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.values.md}px) {
    padding-bottom: 1rem;
  }
`;

const ButtonContainer = styled(Grid)`
  gap: 1rem;
  justify-content: center;
  padding: 2rem 0 2rem 0;
`;
const ModalContainer = styled(motion.div)`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background-color: transparent;
  background-image: linear-gradient(
    to right,
    rgba(255, 0, 0, 0),
    rgba(0, 0, 0, 1)
  );
  z-index: 9999;
  backdrop-filter: blur(60px);
  font-family: 'HCo Gotham SSm';
`;
const ModalContent = styled(motion.div)`
  padding: 12px 32px;
  color: white;
  width: 100%;
  text-align: left;
`;
const ModalHeader = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Line = styled(motion.div)`
  background-image: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.3)
  );
  height: 1px;
  width: 100%;
  margin: 4px 0px;
`;
const CustomH3 = styled('h3')`
  font-family: 'HCo Gotham SSm';
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  p > a {
    color: #3ab4eb;
  }
`;

const CustomP = styled('p')`
  font-size: 12px;
`;
interface HeroProps {
  sectionTypes?: {
    id: string;
    title: string;
    subTitle: { raw: RichTextContent };
    description: { raw: RichTextContent };
    ctaButtonText: string;
    secondaryButtonText: string;
  }[];
}

const Hero: React.FC<HeroProps> = ({ sectionTypes }) => {
  const [{ purchased }] = useGlobalState();
  const theme = useTheme();
  let background = '';
  let specifications = '';
  const [modal, setModal] = useState(false);
  const { setOpen } = useContext(SupportContext);
  const hasPurchasedProducts = purchased.length > 0;
  const isMobile = useMediaQuery(
    `(max-width: ${theme.breakpoints.values.md}px)`,
  );
  const handleModal = () => {
    setModal(true);
  };
  const handleSupport = () => {
    setOpen(true);
  };
  const modalVariants = {
    hidden: {
      opacity: 0,
      x: '100vw',
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: 'spring',
        delay: 0.4,
        duration: 1,
        ease: 'easeInOut',
      },
    },
    exit: {
      y: '100vh',
      transition: { ease: ' easeInOut' },
    },
  };

  const { laptop, specification } = useStaticQuery(graphql`
    query HeroBackground {
      laptop: allGraphCmsAsset(
        filter: { fileName: { glob: "laptop-center-dot*" } }
      ) {
        nodes {
          url
        }
      }

      shopifyBrandImage: allGraphCmsAsset(
        filter: { fileName: { glob: "ShopPay.png" } }
      ) {
        nodes {
          gatsbyImageData
        }
      }

      specification: allGraphCmsModalSpec(
        filter: { specsTitle: { eq: "SPECS" } }
      ) {
        nodes {
          specsTitle
          specDatasheet {
            html
          }
          specifications {
            ... on GraphCMS_SpecificationsComponent {
              id
              specificationName
              specificationDescription {
                html
              }
            }
          }
        }
      }
    }
  `);
  // new query

  // try catch to ignore this line the unit test
  try {
    background = laptop.nodes?.[0]?.url;
    specifications = specification?.nodes[0]?.specifications;
  } catch (error) {}

  // eslint-disable-next-line react/no-unstable-nested-components
  const HeroPriceCard = (
    ctaButtonText?: string,
    secondaryButtonText?: string,
    // eslint-disable-next-line consistent-return
  ) => {
    if (isMobile) {
      return (
        <div>
          <ButtonContainer
            flexDirection={isMobile ? 'column' : 'row'}
            alignItems="center"
          >
            {!hasPurchasedProducts && (
              <div onClick={() => handleSupport()}>
                <Button variant="primary" color="cyan" text={ctaButtonText} />
              </div>
            )}
            {hasPurchasedProducts && (
              <div onClick={() => navigate('/user/support')}>
                <Button variant="primary" color="cyan" text={ctaButtonText} />
              </div>
            )}
            <div onClick={() => handleModal()}>
              <Button
                variant="outline"
                style={{ width: '80%', marginTop: '20px' }}
                color="cyan"
                text={secondaryButtonText}
              />
            </div>
          </ButtonContainer>
          {modal ? (
            <ModalContainer
              variants={modalVariants}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              {/* MODAL CONTENT */}
              <ModalContent>
                <ModalHeader>
                  {/* title and close button */}
                  <h3 style={{ letterSpacing: '2px' }}>
                    {specification.nodes[0].specsTitle}
                  </h3>
                  <div onClick={() => setModal(false)} className="close-btn">
                    <div className="close-container">
                      <div className="leftright" />
                      <div className="rightleft" />
                    </div>
                  </div>
                </ModalHeader>
                {/* modal body */}
                <div
                  style={{ position: 'relative', display: 'flex', gap: '10px' }}
                >
                  <CustomH3
                    dangerouslySetInnerHTML={{
                      __html: specification?.nodes[0]?.specDatasheet?.html,
                    }}
                  />
                  <div style={{ position: 'relative', display: 'flex' }}>
                    <Path />
                    <div style={{ position: 'relative', fontSize: '8px' }}>
                      Click to see
                    </div>
                  </div>
                </div>
                <Line />
                {specifications.map((specs: ISpec) => (
                  <div style={{ margin: '0px' }} key={specs?.id}>
                    <h4 style={{ margin: '0px' }}>
                      {specs?.specificationName}
                    </h4>
                    <CustomP
                      dangerouslySetInnerHTML={{
                        __html: specs?.specificationDescription?.html,
                      }}
                    />
                    <Line />
                  </div>
                ))}
              </ModalContent>
            </ModalContainer>
          ) : (
            ''
          )}
        </div>
      );
      // eslint-disable-next-line prettier/prettier
    }
  };

  return (
    <>
      {sectionTypes?.map((sectionType) => {
        if (sectionType?.id?.includes(SectionTypes.HERO)) {
          return (
            <div
              key={sectionType?.id}
              style={{
                backgroundImage: `url(${background})`,
                backgroundBlendMode: 'normal',
                backgroundSize: 'cover',
                marginTop: isMobile ? '0px' : '28px',
              }}
            >
              <Container>
                <Grid item xs>
                  <HeroAnimation
                    id="hero-content"
                    title={sectionType?.title}
                    subTitle={sectionType?.subTitle}
                    description={sectionType?.description}
                    ctaButtonText={sectionType?.ctaButtonText}
                    secondaryButtonText={sectionType?.secondaryButtonText}
                  />
                  {HeroPriceCard(
                    sectionType?.ctaButtonText,
                    sectionType?.secondaryButtonText,
                  )}
                </Grid>
              </Container>
            </div>
          );
        }
        return null;
      })}
    </>
  );
};

export default Hero;
