/* eslint-disable react/jsx-no-comment-textnodes */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useContext } from 'react';
import { RichText } from '@graphcms/rich-text-react-renderer';
import { motion } from 'framer-motion';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { styled, useMediaQuery, useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { RichTextContent } from '@graphcms/rich-text-types';
import { CartContext } from '../../context/CartContext';
import Button from '../button';
import { useGlobalState, ISpec } from '../GlobalState';
import Image from '../Image';
import Path from '../../assets/images/path.svg';
import { SupportContext } from '../../context/SupportContext';

const HeroImage = styled(motion.div)`
  position: relative;
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  transform-origin: center center;
  top: 0;
  bottom: 0;
`;
const Overlay = styled(motion.div)`
  width: 100vw;
  height: 100vh;
  inset: 0;
  background-color: transparent;
  position: absolute;
  overflow: hidden;
  z-index: 999;

  @media (max-width: 1280px) {
    h4 {
      font-size: 10px;
      line-height: 8px;
    }
    .modac {
      height: 100vh !important;
    }
    h3 {
      font-size: 12px;
    }
    .close-btn {
      width: 20px;
      height: 20px;
    }
    .leftright,
    .rightleft {
      width: 10px;
    }
  }
`;

const ModalContainer = styled(motion.div)`
  position: fixed;
  top: 0;
  right: 0;
  width: 40%;
  height: auto;
  background-color: transparent;
  background-image: linear-gradient(
    to right,
    rgba(255, 0, 0, 0),
    rgba(0, 0, 0, 1)
  );
  z-index: 9999;
  backdrop-filter: blur(60px);
`;
const ModalContent = styled(motion.div)`
  padding: 12px 32px;
  color: white;
  width: 100%;
  text-align: left;
  font-family: 'HCo Gotham SSm';
  @media (max-width: 1280px) {
    padding: 4px 32px;
  }
`;
const ModalHeader = styled(motion.div)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Line = styled(motion.div)`
  background-image: linear-gradient(
    to right,
    transparent,
    rgba(255, 255, 255, 0.3)
  );
  height: 1px;
  width: 100%;
  margin: 4px 0px;
  @media (max-width: 1280px) {
    margin: 2px 0px;
  }
`;

const ButtonContainer = styled(Grid)`
  gap: 1rem;
  justify-content: left;
  padding: 1rem 0 1rem 0;
`;

const LaptopImageContainer = styled(motion.div)`
  position: relative;
  max-width: 742px;
  display: grid;
  align-items: center;
  justify-content: center;
  margin: 0 0 0;
  top: 0;
  bottom: 0;
`;

const CustomH3 = styled('h3')`
  font-family: 'HCo Gotham SSm';
  font-size: 14px;
  line-height: 18px;
  margin: 0;
  p > a {
    color: #3ab4eb;
  }
  @media (max-width: 1280px) {
    font-size: 12px;
  }
`;

const CustomP = styled('p')`
  font-size: 12px;
  @media (max-width: 1280px) {
    font-size: 10px;
  }
`;

const Headings = styled('div')`
  display: flex;
  align-items: left;
  flex-direction: column;
`;
const Heading = styled('div')`
  text-align: left;
  margin: 1rem 0 0;
  div:nth-of-type(2) {
    font-size: 25px;
  }
`;

const SubHeading = styled('div')`
  max-width: 820px;
  text-align: left;
`;

interface HeroAnimationProps {
  id: string;
  title?: string;
  subTitle?: { raw: RichTextContent };
  description?: { raw: RichTextContent };
  ctaButtonText?: string;
  secondaryButtonText?: string;
}

const Hero: React.FC<HeroAnimationProps> = ({
  id,
  title,
  subTitle,
  description,
  ctaButtonText,
  secondaryButtonText,
}) => {
  const theme = useTheme();
  const [{ purchased }] = useGlobalState();
  const { setShowCart } = useContext(CartContext);
  const { setOpen } = useContext(SupportContext);
  const [modal, setModal] = useState(false);
  const isMobile = useMediaQuery(
    `(max-width: ${theme.breakpoints.values.md}px)`,
  );

  const handleModal = () => {
    setModal(true);
    setShowCart(false);
  };

  const handleSupport = () => {
    setOpen(true);
  };
  const hasPurchasedProducts = purchased.length > 0;
  const handleChild = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };
  const modalVariants = {
    hidden: {
      opacity: 0,
      x: '100vw',
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        type: 'spring',
        delay: 0.4,
        duration: 1,
        ease: 'easeInOut',
      },
    },
    exit: {
      y: '100vh',
      transition: { ease: ' easeInOut' },
    },
  };
  const { laptop, shopifyBrandImage, specification } = useStaticQuery(graphql`
    query HeroImages {
      laptop: allGraphCmsAsset(
        filter: { fileName: { glob: "laptop-center-one-min*" } }
      ) {
        nodes {
          gatsbyImageData
        }
      }
      shopifyBrandImage: allGraphCmsAsset(
        filter: { fileName: { glob: "ShopPay.png" } }
      ) {
        nodes {
          gatsbyImageData
        }
      }
      specification: allGraphCmsModalSpec(
        filter: { specsTitle: { eq: "SPECS" } }
      ) {
        nodes {
          specsTitle
          specDatasheet {
            html
          }
          specifications {
            ... on GraphCMS_SpecificationsComponent {
              id
              specificationName
              specificationDescription {
                html
              }
            }
          }
        }
      }
    }
  `);

  const specifications = specification?.nodes[0]?.specifications;
  return (
    <div id={id}>
      <Grid container spacing={2} style={{ padding: '0px' }}>
        <Grid
          item
          xs={12}
          sm
          style={{ paddingLeft: '50px', paddingTop: '30px' }}
        >
          <Headings>
            <Heading>
              <Typography
                variant={isMobile ? 'h2' : 'h1'}
                style={{
                  textAlign: isMobile ? 'center' : 'left',
                  marginBottom: '2px',
                }}
              >
                {title?.split('\n').map((line: string) => (
                  <div style={{ lineHeight: '50px' }} key={line}>
                    {line}
                  </div>
                ))}
              </Typography>
            </Heading>
            <div
              style={{
                display: isMobile ? 'none' : 'block',
              }}
            >
              {subTitle && (
                <SubHeading
                  style={{
                    marginTop: theme.spacing(4),
                    textAlign: isMobile ? 'center' : 'left',
                    paddingRight: '100px',
                  }}
                >
                  <RichText
                    content={subTitle?.raw}
                    renderers={{
                      p: ({ children }) => (
                        <Typography
                          variant="bodyL"
                          sx={{ fontWeight: 'bold', fontSize: '18px' }}
                        >
                          {children}
                        </Typography>
                      ),
                    }}
                  />
                </SubHeading>
              )}
              {description && (
                <SubHeading
                  style={{
                    marginTop: theme.spacing(4),
                    textAlign: isMobile ? 'center' : 'left',
                    paddingRight: '40px',
                    lineHeight: '40px'
                  }}
                >
                  <RichText
                    content={description?.raw}
                    renderers={{
                      p: ({ children }) => (
                        <Typography
                          variant="bodyM"
                          sx={{
                            fontSize: '20px',
                            textAlign: 'justify',
                            fontWeight: 'bold',
                          }}
                        >
                          {children}
                        </Typography>
                      ),
                    }}
                  />
                </SubHeading>
              )}

              <ButtonContainer
                container
                flexDirection={isMobile ? 'column' : 'row'}
                alignItems="left"
                marginTop="70px"
              >
                {!hasPurchasedProducts && (
                  <div onClick={() => handleSupport()}>
                    <Button
                      variant="primary"
                      color="cyan"
                      text={ctaButtonText}
                    />
                  </div>
                )}
                {hasPurchasedProducts && (
                  <div onClick={() => navigate('/user/support')}>
                    <Button
                      variant="primary"
                      color="cyan"
                      text={ctaButtonText}
                    />
                  </div>
                )}

                <div onClick={() => handleModal()}>
                  <Button
                    variant="outline"
                    color="cyan"
                    text={secondaryButtonText}
                  />
                </div>
              </ButtonContainer>
            </div>
          </Headings>
        </Grid>
        <Grid
          item
          style={{
            background:
              'radial-gradient(50% 50% at 50% 50%, rgb(43, 146, 235, 97) 30%, rgba(69, 199, 255, 0) 100%)',
            opacity: '0.8',
            paddingTop: '0px',
          }}
        >
          {laptop?.nodes?.length > 0 && (
            <HeroImage>
              <LaptopImageContainer>
                <Image
                  image={laptop.nodes?.[0]?.gatsbyImageData}
                  alt="HP Dev One"
                />
              </LaptopImageContainer>
            </HeroImage>
          )}
        </Grid>
      </Grid>
      {modal ? (
        <Overlay onClick={() => setModal(false)}>
          <ModalContainer
            className="modac"
            onClick={(e) => handleChild(e)}
            variants={modalVariants}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            {/* MODAL CONTENT */}
            <ModalContent>
              <ModalHeader className="modaH">
                {/* title and close button */}
                <h3 style={{ letterSpacing: '2px' }}>
                  {specification.nodes[0].specsTitle}
                </h3>
                <div onClick={() => setModal(false)} className="close-btn">
                  <div className="close-container">
                    <div className="leftright"></div>
                    <div className="rightleft"></div>
                  </div>
                </div>
              </ModalHeader>
              {/* modal body */}
              <div
                style={{ position: 'relative', display: 'flex', gap: '10px' }}
              >
                <CustomH3
                  dangerouslySetInnerHTML={{
                    __html: specification?.nodes[0]?.specDatasheet?.html,
                  }}
                />
                <div style={{ position: 'relative', display: 'flex' }}>
                  <Path />
                  <div style={{ position: 'relative', fontSize: '8px' }}>
                    Click to see
                  </div>
                </div>
              </div>
              <Line />
              {specifications.map((specs: ISpec) => (
                <div style={{ margin: '0px' }} key={specs?.id}>
                  <h4 style={{ margin: '0px' }}>{specs?.specificationName}</h4>
                  <CustomP
                    dangerouslySetInnerHTML={{
                      __html: specs?.specificationDescription?.html,
                    }}
                  />
                  <Line />
                </div>
              ))}
            </ModalContent>
          </ModalContainer>
        </Overlay>
      ) : (
        ''
      )}
    </div>
  );
};

const HeroAnimation: React.FC<HeroAnimationProps> = (props) => (
  <Hero {...props} />
);

export default HeroAnimation;
