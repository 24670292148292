import React, { useContext } from 'react';
import Layout from '../components/layout';
// import { PageHeader } from '../components/header';
import SEO from '../components/seo';
import Hero from '../components/home/Hero';
import { PageProps } from './page';
import RichTextRenderer from '../components/RichTextRenderer';
import PromoBanner from '../components/PromoBanner';

const Home: React.FC<PageProps> = ({ pageContext: { page } }) => (
  <Layout>
    <SEO title={page.title} description={page.description} />
    <PromoBanner visible={true} />
    <RichTextRenderer content={page.content} id="overview">
      <Hero />
    </RichTextRenderer>
  </Layout>
);

export default Home;
