import React from 'react';
import { RichText } from '@graphcms/rich-text-react-renderer';
import { NavInView } from './header';

const RichTextRenderer: React.FC<{ content: any; id: string }> = ({
  content,
  children,
  id,
}) => (
  <RichText
    content={content?.raw}
    references={content?.references}
    renderers={{
      p: () => <> </>,
      embed: {
        Section05: ({ anchorLink, sectionTypes, title }) =>
          title?.toLowerCase().includes(id) ? (
            <NavInView id={anchorLink} isdefault={false}>
              {React.Children.map(children, (child) =>
                React.isValidElement(child)
                  ? React.cloneElement(child, {
                      title,
                      sectionTypes,
                    })
                  : null,
              )}
            </NavInView>
          ) : (
            <> </>
          ),
      },
    }}
  />
);

export default RichTextRenderer;
